<template>
  <div>
    <table-spinner :loading="loading"></table-spinner>
    <b-card-group deck v-if="!loading">
      <b-card class="col-md-4" v-for="service in services" :key="service.id">
        <div class="text-primary font-weight-bolder">12 MONTHS</div>
        <h1>{{ service.display_name }}</h1>
        <b-card-body class="pl-0">
          <b-card-text class="mb-0"
            ><span class="h1 text-primary font-weight-bolder"
              >{{ service.price }}
            </span>
            <b>{{ service.currency }}/month</b></b-card-text
          >
          <b-card-text
            ><span class="font-weight-bolder"
              >{{ paymentExtraFeePercent }}% + {{ paymentExtraFee }}
              {{ service.currency }}</span
            >
            payment fees apply (PayPal)</b-card-text
          >
          <div>
            <service-description
              :description="service.description"
              @updateDescription="updateDescription($event, service)"
            ></service-description>
          </div>
          <div class="text-primary cursor-pointer mb-1" @click="expandList">
            More info
            <feather-icon
              size="20"
              :icon="isExpanded ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            />
          </div>
          <div v-if="isExpanded">
            <feature-list
              :service="service"
              @addFeature="addFeature"
              @deleteFeature="deleteFeature"
              @updateFeature="updateFeature"
            ></feature-list>
          </div>
        </b-card-body>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import { BCard, BCardText, BCardGroup, BCardBody } from "bootstrap-vue";
import {
  PAYMENT_EXTRA_FEE_IN_EUR,
  PAYMENT_EXTRA_FEE_PERCENT,
} from "@/services/utils/util";
import { mapActions } from "vuex";
import TableSpinner from "@/layouts/components/TableSpinner.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import FeatureList from "@/layouts/components/services/FeatureList.vue";
import ServiceDescription from "@/layouts/components/services/ServiceDescription.vue";
export default {
  components: {
    BCard,
    BCardText,
    BCardGroup,
    BCardBody,
    TableSpinner,
    FeatherIcon,
    FeatureList,
    ServiceDescription,
  },
  data() {
    return {
      loading: false,
      isExpanded: false,
    };
  },
  computed: {
    authUser() {
      return this.$store.getters["auth/authUser"];
    },
    isAdmin() {
      return this.$store.getters["auth/isAdmin"];
    },
    services() {
      return this.$store.getters["services/getServicesListData"];
    },
    stateLoading() {
      return this.$store.getters["services/isLoading"];
    },
    paymentExtraFee() {
      return this.$store.getters["adminSystemConfig/getSysConfigValue"](
        PAYMENT_EXTRA_FEE_IN_EUR
      );
    },
    paymentExtraFeePercent() {
      return this.$store.getters["adminSystemConfig/getSysConfigValue"](
        PAYMENT_EXTRA_FEE_PERCENT
      );
    },
  },
  mounted() {
    this.getServices().catch(console.error);
    this.getSysConfig().catch(console.error);
  },
  methods: {
    ...mapActions({
      getServices: "services/getServices",
      updateServices: "services/updateService",
      getSysConfig: "adminSystemConfig/getSysConfig",
    }),
    async deleteFeature(index, service) {
      this.$swal({
        title: "Are you sure you want to delete this item?",
        type: "warning",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const features = service.features;
          features.splice(index, 1);

          await this.updateServices({
            id: service.id,
            features: features,
          });
        }
      });
    },
    async updateFeature(index, service, updatedFeature) {
      const features = service.features;
      this.$set(features, index, updatedFeature);
      await this.updateServices({
        id: service.id,
        features: [...features],
      });
    },
    async addFeature(newFeature, service) {
      const features = [newFeature, ...(service.features ?? [])];
      try {
        await this.updateServices({
          id: service.id,
          features: [...features],
        });
      } finally {
        await this.onRefreshPage();
      }
    },
    expandList() {
      this.isExpanded = !this.isExpanded;
    },
    async updateDescription(updatedDescription, service) {
      await this.updateServices({
        id: service.id,
        description: updatedDescription,
      });
    },
    async onRefreshPage() {
      this.loading = true;
      try {
        await this.getServices();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.dark-layout .check-icon {
  color: var(--green) !important;
}
.dark-layout .ql-stroke {
  fill: none;
  stroke: #f8f8ff !important;
}
.dark-layout .ql-fill {
  fill: #f8f8ff !important;
  stroke: none;
}
.dark-layout .ql-picker {
  color: #f8f8ff !important;
}
.dark-layout .ql-picker-item {
  color: black;
}
</style>
