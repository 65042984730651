<template>
  <div class="d-flex mb-1">
    <div
      v-if="description && !isEdit"
      v-html="description"
      class="text-wrap text-break"
    ></div>
    <b-form-textarea
      v-model="updatedDescription"
      v-if="isEdit && isAdmin"
      rows="5"
    />
    <button class="btn p-0 ml-2" @click="toggleEdit" v-if="isAdmin">
      <feather-icon icon="EditIcon" size="16" class="text-info"></feather-icon>
    </button>
    <button
      class="btn p-0 ml-1"
      @click="$emit('updateDescription', updatedDescription)"
      v-if="isEdit && isAdmin"
    >
      <feather-icon
        icon="SaveIcon"
        size="16"
        class="text-success"
      ></feather-icon>
    </button>
  </div>
</template>

<script>
import { BFormTextarea } from "bootstrap-vue";
export default {
  components: {
    BFormTextarea,
  },
  data() {
    return {
      isEdit: false,
      updatedDescription: this.description,
    };
  },
  props: {
    description: {
      type: String,
    },
  },
  computed: {
    isAdmin() {
      return this.$store.getters["auth/isAdmin"];
    },
  },
  methods: {
    toggleEdit() {
      this.isEdit = !this.isEdit;
    },
  },
};
</script>

<style></style>
