<template>
  <div class="w-100 mb-1 ml-2 d-flex align-items-center">
    <feather-icon
      icon="CheckCircleIcon"
      :size="!isEdit ? '16' : '23'"
      class="mr-1 check-icon"
    ></feather-icon>
    <span v-if="!isEdit">{{ feature }}</span>
    <b-form-input v-model="editFeature" v-if="isEdit && isAdmin" />
    <button class="btn p-0 ml-2" @click="toggleEdit" v-if="isAdmin">
      <feather-icon icon="EditIcon" size="16" class="text-info"></feather-icon>
    </button>
    <button
      class="btn p-0 ml-1"
      @click="$emit('updateFeature', { updatedFeature: editFeature })"
      v-if="isEdit && isAdmin"
    >
      <feather-icon
        icon="SaveIcon"
        size="16"
        class="text-success"
      ></feather-icon>
    </button>
    <button v-if="isAdmin" @click="$emit('deleteFeature')" class="btn p-0 ml-1">
      <feather-icon
        icon="TrashIcon"
        size="16"
        class="text-danger"
      ></feather-icon>
    </button>
  </div>
</template>

<script>
import { BFormInput } from "bootstrap-vue";
export default {
  components: {
    BFormInput,
  },
  props: {
    feature: {
      type: String,
    },
  },
  data() {
    return {
      isEdit: false,
      editFeature: this.feature,
    };
  },
  methods: {
    toggleEdit() {
      this.isEdit = !this.isEdit;
    },
  },
  computed: {
    isAdmin() {
      return this.$store.getters["auth/isAdmin"];
    },
  },
};
</script>

<style></style>
