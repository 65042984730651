<template>
  <div>
    <feature-list-item
      v-for="(feature, index) in service.features"
      :key="index"
      :feature="feature"
      @updateFeature="
        $emit('updateFeature', index, service, $event.updatedFeature)
      "
      @deleteFeature="$emit('deleteFeature', index, service)"
    ></feature-list-item>
    <div v-if="isAdmin" class="mt-3">
      <label for="new-item">New item: </label>
      <b-form-input id="new-item" v-model="newFeature"></b-form-input>
      <button
        v-if="isAdmin"
        class="btn btn-success mt-1 w-100"
        @click="$emit('addFeature', newFeature, service)"
      >
        Add item to list
      </button>
    </div>
  </div>
</template>

<script>
import { BFormInput } from "bootstrap-vue";
import FeatureListItem from "./FeatureListItem.vue";
export default {
  components: {
    BFormInput,
    FeatureListItem,
  },
  data() {
    return {
      newFeature: "",
    };
  },
  props: {
    service: {
      type: Object,
    },
  },
  computed: {
    isAdmin() {
      return this.$store.getters["auth/isAdmin"];
    },
  },
};
</script>
